<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right mb-2">
            <button
              type="button"
              class="btn btn-primary mr-2"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
          <div class="widget-content widget-content-area br-6">
            <div class="text-left"></div>

            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive">
              <table
                id="EXPIRED_SUB_REMARKS"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th class="text-danger">Expiry Date</th>
                    <th>Contact Email</th>
                    <th>Contact Phone</th>
                    <th>Address</th>
                    <th>Elderly</th>
                    <th>Last Subscription Amount</th>
                    <th>Last Subscription Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(record, index) in records"
                    :key="`${record.id}-${index}`"
                  >
                    <td>{{ record.user.name }}</td>
                    <td class="text-danger">
                      {{ formatDate(record.created_at)}}
                    </td>
                    <td>{{ record.user.email }}</td>
                    <td>{{ record.user.phone }}</td>
                    <td>{{ record.user.address == null ? 'Not available' : record.user.address }}</td>
                    <td>{{ record.patient.firstname + ' ' + record.patient.lastname }}</td>
                    <td>
                      {{
                        formatMoney(
                          record.user.transactions[record.user.transactions.length - 1]
                            .pay_amount
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatDate(
                          record.user.transactions[record.user.transactions.length - 1]
                            .created_at
                        )
                      }}
                    </td>
                    <td class="d-flex">
                      <div class="text-center">
                        <b-button
                          variant="outline-success"
                          class="d-flex"
                          @click.prevent="redirect(record)"
                        >
                          <i class="fa fa-comment"></i>
                          <b-badge variant="light">
                            {{ remarksCount(record) }}
                          </b-badge>
                        </b-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Search Modal -->
      <div
        class="modal fade"
        id="searchModal"
        role="dialog"
        aria-labelledby="searchModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="dateSearch">Date Search</label>
                    <select
                      class="form-control"
                      v-model="search"
                      id="dateSearch"
                    >
                      <option value="all">All</option>
                      <option value="thisweek">This Week (From Monday)</option>
                      <option value="pastweek">Past Week (Last 7 days)</option>
                      <option value="lastmonth">Last Month</option>
                      <option value="thismonth">This Month</option>
                      <option value="thisyear">This Year</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>

                  <div class="form-group" v-if="search == 'custom'">
                    <label>Select a range</label>
                    <div class="input-group">
                      <flat-pickr
                        v-model="date"
                        :config="config"
                        class="form-control"
                        placeholder="Select date"
                        @on-close="handlePicker()"
                        name="date"
                      >
                      </flat-pickr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Discard
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="handleSearch()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { gerohttp } from "@/utils/gerocarehttp";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { DatePickerConfig } from "@/models/datepicker.js";
import $ from "jquery";

export default {
  name: "ExpiredSubRemarks",
  components: {},

  data() {
    return {
      loading: false,
      records: {},
      search: 'thismonth',

      date: null,
      config: new DatePickerConfig(),
    };
  },

  mounted() {
    this.fetchRecords();
  },

  methods: {
    initTable() {
      var dt = $("#EXPIRED_SUB_REMARKS").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([ 6, 7]).visible(false);
    },

    remarksCount(record){
      return record.remarks.length
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchRecords();
        $("#searchModal").modal("hide");
      }
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    fetchRecords() {
      this.loading = true;

      gerohttp
        .post(geroendpoints.ALL_EXPIRED_SUB, {
          _key: process.env.VUE_APP_API_SECRET_KEY,
          params: {
            search: this.search,
            customStart: this.customStart,
            customEnd: this.customEnd,
          },
        })
        .then((response) => {
          this.records = response;
          let ids = []

          response.forEach((record) => ids.push(record.user_id))

          if (ids.length > 0) {
            this.fetchReminder(ids);

            this.fetchRemarksFromCRM(ids);
          }else {
            this.loading = false
          }
          
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        })
        
    },

    fetchReminder(ids){
      http
        .post(endpoints.EXPIRED_SUB_REMINDER, {
          expiredSubscriberIds: ids
        })
        .then((response) => {
          this.reminder = response.data

          this.records.forEach((record) => {
            record.reminder = this.reminder.filter((x) => {
              return record.user_id == x.sponsor_id;
            });
          });
        })
        .catch(() => {

        })
    },

    fetchRemarksFromCRM(x) {

      http
        .post(endpoints.FETCH_EXPIRED_SUB_REMARKS, {
          ids: x,
        })
        .then((response) => {
          this.remarks = response;

          this.records.forEach((record) => {
            record.remarks = this.remarks.filter((x) => {
              return record.user_id == x.sponsor_id;
            });
          });

          setTimeout(() => {
            this.initTable();
          }, 500);
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        });
    },

    redirect(x) {
      this.$router.push({
        name: "Expired Subscription Calls Records",
        params: { id: x.id, data: x },
      });
    },

    findExpiredElderlies(x) {
      var names = "";
      x.forEach((x) => {
        if (x.visit_count == 0) {
          names += `${x.firstname} ${x.lastname}, `;
        }
      });
      return names;
    },

    findExpiryDates(x) {
      let dates = "";
      x.forEach((x) => {
        if (x.visit_count == 0) {
          if (x.lastappointment != null) {
            dates += `${this.formatDate(x.lastappointment.appointment_date)}, `;
          }
        }
      });
      return dates;
    },
  },
};
</script>
